.App {
  text-align: center;
}
header {
  display: flex;
  flex-direction: column;
  width: 200px;
  position: fixed;
  padding: 10px;
}

a {
  color: black;
}

#biographie {
  max-width: 882px;
  margin: 0 auto;
  text-align: left;
  line-height: 1.5;
  padding: 0 10px;
}

#biographie img{
  width: 100%;
  margin: 100px 0 25px;
}

@media screen and (max-width: 1100px)
{
  header {
    display: flex;
    flex-direction: revert;
    width: 100%;
    position: fixed;
    padding: 10px;
    box-sizing: border-box;
    background: white;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f4f2f2;
  }
  header img {
    width: 35%;
    max-width: 200px;
  }
}